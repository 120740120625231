import React from 'react';
import { Route, Routes } from 'react-router';
import ReportView from '../pages/Reports/ReportView';
import OrderSearch from '../pages/Reports/OrderSearch';

const ReportsRouter = () => {
  return (
    <Routes>
      <Route path="/fsa-yuOrder-sales" element={<ReportView />} />
      <Route path="/fsa-yuOrder-search" element={<OrderSearch />} />
    </Routes>
  );
};

export default ReportsRouter;
