import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./auth.reducer";
import dashboardReducer from "./dashboard.reducer";
import tutorialsReducer from "./tutorials.reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
  tutorials: tutorialsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;